import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import imagePathGenerator from '../../utils/imagePathGenerator';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, Typography, useTheme, List, ListItem, ListItemText } from '@material-ui/core';
import { Section, Parallax, Container, MediaCard, Modal } from 'gatsby-theme-core/src/components';
import { Wordmark } from '../wordmark';

export type ManagementProps = Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const Management = ({ ...props }: ManagementProps) => {
  const theme = useTheme();
  const data: any = useStaticQuery(graphql`
    {
      criteria: allInvestmentCriteriaDataJson {
        edges {
          node {
            criteria
          }
        }
      }
      team: allTeamDataJson {
        edges {
          node {
            name
            position
            image
            description
          }
        }
      }
    }
  `);

  const { criteria, team } = data;
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState(null);

  const openModal = (e: any) => {
    setSelection(team.edges[e.target.id].node);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal isOpen={open} onRequestClose={closeModal} data={selection} />

      <span id="management"></span>
      <Parallax image={imagePathGenerator('about-future-work-4.jpg')} strength={300} classname="white-text uppercase">
        <Grid container item justify="center" xs={12}>
          <Section spacing={1}>
            <Wordmark variant="h4" color="#FFFFFF" />
          </Section>
        </Grid>
        <Grid container item justify="center" xs={12}>
          <Typography variant="h1">Management</Typography>
        </Grid>
      </Parallax>
      <Container>
        <Section spacing={5}>
          <Section spacing={3}>
            <Grid container justify="center">
              <Grid container item xs={12} sm={6} justify="center" alignItems="center">
                <Section spacing={2}>
                  <Typography variant="h2" className="uppercase">
                    Our Management
                  </Typography>
                </Section>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Section spacing={1}>
                  <Typography variant="h3" className="left uppercase">
                    A Combination of Knowledge, Leadership, and Vision
                  </Typography>
                </Section>
                <Section spacing={1} classname="justify">
                  <Typography variant="caption" className="secondary-dark-text">
                    As experts across all aspects of flexible workspace, serviced offices and coworking, our management
                    team has been called upon for decades to advise others on their investments and acquisitions. Now,
                    we thought it was time we did our own. Combining extensive knowledge, leadership, and vision, our
                    management team is the singularly most experienced in our field, globally. Utilizing our unique
                    expertise, we provide investment and strategic support to companies by acting as a catalyst,
                    offering accelerated growth opportunities and connections.
                  </Typography>
                </Section>
              </Grid>
            </Grid>
          </Section>
          <Section spacing={3}>
            <Grid container justify="center">
              <Grid container item xs={12} sm={6} justify="center" alignItems="center">
                <Section spacing={2}>
                  <Typography variant="h2" className="uppercase">
                    Our Fundamentals
                  </Typography>
                </Section>
              </Grid>
              <Grid container item direction="row" xs={12} sm={6}>
                <Grid container item xs={12}>
                  <Section spacing={1}>
                    <Typography variant="h3" className="left uppercase">
                      A Common Philosophy
                    </Typography>
                  </Section>
                  <Section spacing={1} classname="justify">
                    <Typography variant="caption" className="secondary-dark-text">
                      Our investments reflect our fund managers' convictions rather than market indices. The team has
                      unique knowledge gained by extensive operations and management within the flexible workspace
                      industry to predict rather than experience market trends.
                    </Typography>
                  </Section>
                </Grid>
                <Grid container item xs={12}>
                  <Section spacing={1}>
                    <Typography variant="h3" className="left uppercase">
                      Diversity of Views
                    </Typography>
                  </Section>
                  <Section spacing={1} classname="justify">
                    <Typography variant="caption" className="secondary-dark-text">
                      Our managers believe in the principle of cross fertilization. Each gives their view of an asset
                      class or geographic area. These points of view all influence the investment strategy.
                    </Typography>
                  </Section>
                </Grid>
                <Grid container item xs={12}>
                  <Section spacing={1}>
                    <Typography variant="h3" className="left uppercase">
                      Diversity of Skills
                    </Typography>
                  </Section>
                  <Section spacing={1} classname="justify">
                    <Typography variant="caption" className="secondary-dark-text">
                      Our management team was assembled with the flexible workspace sector expertise in mind, but with a
                      wide range of skills and global reach in order to seize investment opportunities all over the
                      world.
                    </Typography>
                  </Section>
                </Grid>
              </Grid>
            </Grid>
          </Section>
          <Section spacing={3}>
            <Grid container justify="center">
              <Grid container item xs={12} sm={6} justify="center" alignItems="center">
                <Section spacing={2}>
                  <Typography variant="h2" className="uppercase center">
                    Investment Criteria
                  </Typography>
                </Section>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Section spacing={1}>
                  <Typography variant="caption" className="left secondary-dark-text">
                    <List>
                      {criteria.edges.map((criteriaDescription: any, i: number) => (
                        <ListItem key={i} divider>
                          <ListItemText>
                            <Typography className="light">{criteriaDescription.node.criteria}</Typography>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Typography>
                </Section>
              </Grid>
            </Grid>
          </Section>
          <Section spacing={4}>
            <Section spacing={3}>
              <Typography variant="h2" className="uppercase center">
                Meet The Team
              </Typography>
            </Section>
            <Section spacing={2}>
              <Grid container justify="center">
                {team.edges.map((teammate: any, i: number) => (
                  <MediaCard
                    key={i}
                    id={i}
                    name={teammate.node.name}
                    position={teammate.node.position}
                    image={teammate.node.image}
                    openModal={openModal}
                  />
                ))}
              </Grid>
            </Section>
          </Section>
          <Typography variant="h2" className="uppercase center">
            Professional Advisors
          </Typography>
          <Section spacing={4}>
            <Grid container justify="center">
              <Grid container item xs={12} lg={3} xl={2} justify="center">
                <Section spacing={3}>
                  <a href="https://www.hollandhart.com/">
                    <img
                      style={{ boxShadow: theme.shadows[12] }}
                      src={imagePathGenerator('fowf-advisor-1.jpg')}
                      alt="advisor"
                    />
                  </a>
                </Section>
              </Grid>
              <Grid container item xs={12} lg={3} xl={2} justify="center">
                <Section spacing={3}>
                  <a href="https://www.alterdomus.com/">
                    <img
                      style={{ boxShadow: theme.shadows[12] }}
                      src={imagePathGenerator('fowf-advisor-2.jpg')}
                      alt="advisor"
                    />
                  </a>
                </Section>
              </Grid>
              <Grid container item xs={12} lg={3} xl={2} justify="center">
                <Section spacing={3}>
                  <a href="https://spicerjeffries.com/">
                    <img
                      style={{ boxShadow: theme.shadows[12] }}
                      src={imagePathGenerator('fowf-advisor-3.jpg')}
                      alt="advisor"
                    />
                  </a>
                </Section>
              </Grid>
            </Grid>
          </Section>
        </Section>
      </Container>
    </>
  );
};
export default Management;
