import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { useStaticQuery, graphql } from 'gatsby';

type pointInTime = {
  description: string;
  date: string;
};

export type TimelineProps = {
  lineItemColor: string;
  dateBackgroundColor: string;
  textColor: string;
} & Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const timeline = ({ ...props }: TimelineProps) => {
  const data: any = useStaticQuery(graphql`
    query MyQuery {
      timeline: allTimelineDataJson {
        edges {
          node {
            date
            description
          }
        }
      }
    }
  `);

  const timelineData = data.timeline.edges;
  return (
    <Timeline lineColor={props.lineItemColor}>
      {timelineData.map((pointInTime: { node: pointInTime }, i: number) => (
        <TimelineItem
          key={i}
          dateText={pointInTime.node.date}
          dateInnerStyle={{
            background: props.dateBackgroundColor,
            color: props.textColor,
          }}
          style={{ color: props.lineItemColor }}
        >
          <p style={{ color: props.textColor }}>{pointInTime.node.description}</p>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default timeline;
