import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Grid, Typography } from '@material-ui/core';
import { Section, Parallax, Container } from 'gatsby-theme-core/src/components';
import imagePathGenerator from '../../utils/imagePathGenerator';
import { Wordmark } from '../wordmark';

export type FocusProps = Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const Focus = ({ ...props }: FocusProps) => {
  return (
    <>
      <span id="focus"></span>
      <Parallax image={imagePathGenerator('future-work-home-3.jpg')} strength={400} classname="white-text uppercase">
        <Grid container item justify="center" xs={12}>
          <Section spacing={1}>
            <Wordmark variant="h4" color="#FFFFFF" />
          </Section>
        </Grid>
        <Grid container item justify="center" xs={12}>
          <Typography variant="h1">
            Our <span className="primary-main-text">Focus</span>
          </Typography>
        </Grid>
      </Parallax>

      <Container>
        <Section spacing={5}>
          <Grid container justify="center">
            <Grid container item xs={12} sm={6} justify="center" alignItems="center">
              <Section spacing={2}>
                <Typography variant="h2" className="uppercase">
                  Always Strategic
                </Typography>
              </Section>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Section spacing={1} classname="justify">
                <Typography variant="caption" className="secondary-dark-text">
                  The Future Of Work Funds focuses on{' '}
                  <span className="semi-bold">late seed and series A stage companies</span> operating at the
                  intersection of the flexible workplace and technology. We work with growth-oriented, cash-flowing
                  companies who are building connections between providers of flexible workspace and user groups of all
                  types, including large enterprise, government and education, and{' '}
                  <span className="semi-bold">whose business and management are capable of rapid scale</span>. Through
                  carefully planned, structured policies, the Future of Work Funds team provides support, strategy and
                  investment that enables rapid acceleration. Together, we will drive the utilization and expansion of
                  the flexible workplace and build extraordinary solutions to power the future of work.
                </Typography>
              </Section>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </>
  );
};
export default Focus;
