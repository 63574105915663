import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Grid, Typography, } from '@material-ui/core';
import { Section, Parallax, Container } from 'gatsby-theme-core/src/components';
import imagePathGenerator from '../../utils/imagePathGenerator';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Wordmark } from '../wordmark'
import Timeline from '../../components/timeline'

export type StoryProps = Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const Story = ({ ...props }: StoryProps) => {
  const theme = useTheme();

  return (
    <>
      <span id="about"></span>
      <Parallax image={imagePathGenerator('fowf-team-web1.jpg')} strength={400} classname="uppercase white-text">
        <Grid container item justify="center" xs={12}>
          <Section spacing={1}>
            <Wordmark variant="h4" color="#FFFFFF" />
          </Section>
        </Grid>
        <Grid container item justify="center" xs={12}>
          <Typography variant="h1">
            About <span className="primary-main-text">Us</span>
          </Typography>
        </Grid>
      </Parallax>
      <Container>
        <Section spacing={5}>
          <Grid container justify="center">
            <Grid container item xs={12} sm={6} justify="center" alignItems="center">
              <Section spacing={3}>
                <Typography variant="h2" className="uppercase">
                  Our Story
                </Typography>
              </Section>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Section spacing={1}>
                <Wordmark variant="h3" className="center uppercase" color={theme.palette.secondary.dark} />
              </Section>
              <Section spacing={1} classname="justify">
                <Typography variant="caption" className="secondary-dark-text">
                  Our story is one of conservative consistency over four decades. The Future Of Work Funds is the
                  culmination of unrivalled experience, innovation, connections and capital in the fields of flexible
                  workspace, technology, and investment management. Our global history of success spans 54 countries and
                  combines a thorough understanding of workplace trends, matched with a passion and assured ability to
                  drive those trends into first mover companies.
                </Typography>
              </Section>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Grid container item justify="center" className="dark-green">
        <Grid container item xs={12} sm={6} justify="center">
          <Timeline textColor="white" dateBackgroundColor="#242a2e" lineItemColor="#58595b"></Timeline>
        </Grid>
      </Grid>
    </>
  );
};
export default Story;
