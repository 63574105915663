import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Header, Footer } from 'gatsby-theme-core/src/components';
import { Management, Story, Focus, Disclaimer, Home } from './sections/sectionIndex';

const LOGO = require('../images/fowf-icon.png');

export type MainProps = Pick<DefaultComponentProps, 'children' | 'path' | 'classname'>;

export const Main = ({ ...props }: MainProps) => {
  return (
    <>
      <Header logo={LOGO} />

      <Home />

      <Story />

      <Focus />

      <Management />

      <hr />

      <Disclaimer />

      <Footer logo={LOGO} />
    </>
  );
};

export default Main;
