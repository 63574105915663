import React from 'react';
import { Router } from '@reach/router';
import { Layout } from 'gatsby-theme-core/src/components';
import Main from '../components/main';
import { AuthRoute } from 'gatsby-plugin-auth';

export const Index = () => {
  return (
    <Layout>
      <Router>
        <AuthRoute path="/" component={Main} />
      </Router>
    </Layout>
  );
};

export default Index;
