import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { useStaticQuery, graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import { Section, Container } from 'gatsby-theme-core/src/components';

export type DisclaimerProps = Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const Disclaimer = ({ ...props }: DisclaimerProps) => {
  const data: any = useStaticQuery(graphql`
    {
      disclosure: allDisclosureDataJson {
        edges {
          node {
            title
            bullets
          }
        }
      }
    }
  `);

  const { disclosure } = data;

  return (
    <Container>
      <Section spacing={4}>
        <Typography variant="caption" className="grey-text">
          {disclosure.edges.map((banner: any, i: number) => (
            <React.Fragment key={i}>
              <span className="bold uppercase">{banner.node.title}:</span>
              <Typography variant="subtitle2" className="justify">
                {banner.node.bullets.map((bullet: string, i: number) => (
                  <p key={i}>{bullet}</p>
                ))}
              </Typography>
            </React.Fragment>
          ))}
        </Typography>
      </Section>
    </Container>
  );
};
export default Disclaimer;
