import React from 'react';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Grid, Typography } from '@material-ui/core';
import { Section, Parallax, Container } from 'gatsby-theme-core/src/components';
import imagePathGenerator from '../../utils/imagePathGenerator';

export type HomeProps = Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

const Home = ({ ...props }: HomeProps) => {
  return (
    <>
      <Parallax image={imagePathGenerator('future-work-home-1.jpg')} strength={300}>
        <Grid container style={{ minHeight: '75vh' }} alignItems="center">
          <Grid container direction="column">
            <Grid item xs={12} className="px-3">
              <Typography variant="h1" className="center white-text">
                Alliance <span className="primary-main-text">Capital</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Section spacing={4}>
                <Typography variant="subtitle1" className="bold uppercase center white-text">
                  <div>People · Place · Technology</div>
                  <div className="light no-transform">Accelerated by Experience, Innovation and Capital.</div>
                </Typography>
              </Section>
            </Grid>
          </Grid>
        </Grid>
      </Parallax>
      <Container>
        <div id="home">
          <Section spacing={6}>
            <Grid container justify="center">
              <Grid className="justify" item xs={12} sm={6}>
                <Typography variant="caption" className="secondary-dark-text">
                  The Future Of Work Funds provides investment capital and strategic guidance for high-growth, ambitious
                  PropTech
                  <span className="semi-bold"> companies that are ready to scale. </span>
                  You bring the vision, we'll bring four decades of extensive industry knowledge and global investment
                  experience.
                </Typography>
              </Grid>
            </Grid>
          </Section>
        </div>
      </Container>
    </>
  );
};
export default Home;
